<template functional>
	<div class="pagination-container">
		<div class="mrow flex wrap align-center">
			<div v-if="props.meta.from" class="mcol-xs-12 mcol-sm-4">
				<div className="meta-info">
					Отображено с
					{{
						`${props.meta.from} по ${props.meta.to} из ${props.meta.total} ${props.itemsName.mult}`
					}}
				</div>
			</div>

			<div class="mcol-xs-12 mcol-sm-8 fluid text-right">
				<el-pagination
					background
					:current-page="props.filters.page"
					:page-size="props.meta.per_page"
					:pager-count="5"
					layout="prev, pager, next"
					:total="props.meta.total"
					@current-change="page => listeners.setFilters({ page: page })"
				/>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	functional: true,
	props: {
		itemsName: { type: Object, required: true },
		meta: { type: Object, required: true },
		filters: { type: Object, required: true }
	}
};
</script>
